.textfield {
  padding: 15px 20px 15px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font: inherit;
  border: none;
  outline: none;
  width: 100%;
  background: #fff;
}
