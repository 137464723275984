.header {
  padding: 0px var(--body-padding);
  height: var(--header-height);
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  background: var(--body-light);
  top: 0;
  left: 0;
  width: 100vw;
  z-index: var(--z-fixed);
}
.header li {
  list-style: none;
}

/* Header - Title [ GURU ] */
.header__title {
  color: var(--first-color);
  flex: 1;
}

/* Header - Navigation [ Home, About ] */
.header__links {
  height: 24px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header__links a {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.header__links svg {
  color: var(--body-dark);
  margin-right: 5px;
}

/* Header - Socila Media [ Facebook, Youtube ] */
.header__socialMedia {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.header__socialMedia svg {
  color: var(--body-dark);
  margin-left: 10px;
}
.header__shadow {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}

/* Header - MenuBar */
.header__menubar {
  display: none;
  padding: var(--body-padding);
}

/* Navigation State */
.navHidden {
  display: none;
}
.navVisible {
  display: flex;
}

/* Responsive - [ Navigation, MenuBar, SocialMedia ] */
@media screen and (max-width: 932px) {
  /* MenuBar */
  .header__menubar {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    height: var(--header-height);
    justify-content: center;
    align-items: center;
    background-color: var(--body-light);
    z-index: calc(100 + var(--z-modal));
  }

  /* Social Media */
  .header__socialMedia {
    position: absolute;
    bottom: calc(var(--header-height) + 50px - 100vh);
    left: 0;
    width: 100vw;
    background-color: var(--body-light);
    justify-content: center;
    z-index: var(--z-modal);
  }
  .header__socialMedia svg {
    color: var(--body-dark);
    width: 50px;
  }

  /* Navigation */
  .header__links {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: var(--body-light);
    flex-direction: column;
    min-height: 100vh;
    z-index: var(--z-modal);
  }

  .header__links a {
    margin: 20px;
    font-size: var(--body-large);
    width: 160px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
}
