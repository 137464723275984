.card {
  width: 450px;
  border-radius: 12px;
  box-shadow: 0px 1px 14px 0px #0000001a;
  overflow: hidden;
  height: auto;
  padding-bottom: 20px;
}
.card__image {
  width: 450px;
  height: 300px;
}
.card__author {
  display: flex;
  padding: 15px 0px 15px 20px;
  align-items: center;
}
.card__author p {
  margin-left: 10px;
}
.card__content {
  padding: 0px 20px 0px 20px;
}
.card__content p {
  color: var(--gray-dark);
}

@media screen and (max-width: 480px) {
  .card {
    width: 90%;
    margin-bottom: 20px;
  }
  .card__image {
    width: 100%;
  }
}
