.commingsoon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - var(--header-height));
  text-align: center;
}
@media screen and (max-width: 550px) {
  .commingsoon img {
    width: 90%;
  }
}
