@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap");

body {
  background: var(--body-light);
  font-size: var(--body-regular);
  font-weight: var(--font-regular);
  font-family: var(--body-font);
  padding: var(--header-height) var(--body-padding) 0px var(--body-padding);
  color: var(--body-dark);
  min-height: 100vh;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
}

h1 {
  font-size: var(--h1);
  line-height: var(--h1-lh);
}
h2 {
  font-size: var(--h2);
  line-height: var(--h2-lh);
}
h3 {
  font-size: var(--h2);
  line-height: var(--h3-lh);
}
h4 {
  font-size: var(--h4);
  line-height: var(--h4-lh);
  letter-spacing: 1px;
}
