.button {
  padding: 10px 20px;
  font-family: var(--body-font);
  font-size: var(--body-regular);
  border: none;
  outline: none;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
}
.bg-first {
  background-color: var(--first-color);
  color: var(--contrast-first-color);
}
.bg-second {
  background-color: var(--second-color) !important;
  color: var(--contrast-second-color) !important;
}
.border-rounded {
  border-radius: 50000px !important;
}
.border-sm {
  border-radius: 5px !important;
}
.shadow-sm {
  box-shadow: 0px 2px 10px 0px #00000040;
}
.shadow-0 {
  box-shadow: none;
}
.bg-red {
  background-color: var(--red-color) !important;
  color: #fff;
}
