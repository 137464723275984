:root {
  --header-height: 60px;
  --body-padding: 111px;

  /* ------------------------ Color ----------------------- */

  --first-color: #8b0bff;
  --contrast-first-color: #f7faff;

  --second-color: #1fe062;
  --contrast-second-color: #f7faff;

  --red-color: #ff4444;

  --body-light: #f7faff;
  --body-dark: #091127;

  --gray-light: #eaeaea;
  --gray-dark: #848282;

  /* ----------------- Font And Typography ---------------- */
  --body-font: "Rubik", sans-serif;
  --body-large: 20px;
  --body-regular: 16px;
  --body-small: 12px;

  --font-regular: 400;
  --font-bold: 700;

  --h1: 40px;
  --h1-lh: 50px;

  --h2: 32px;
  --h2-lh: 45px;

  --h3: 28px;
  --h3-lh: 36px;
  --h4: 20px;
  --h4-lh: 24px;

  /* ----------------------- Z-Index ---------------------- */
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 10000;
}

@media screen and (max-width: 1074px) {
  :root {
    --body-padding: 50px;
  }
}

@media screen and (max-width: 932px) {
  :root {
    --body-padding: 30px;
  }
}

@media screen and (max-width: 550px) {
  :root {
    --body-padding: 10px;
    --body-large: 18px;
  }
}
@media screen and (max-width: 300px) {
  :root {
    --body-padding: 5px;
  }
}

@media screen and (max-width: 450px) {
  :root {
    --h1: 36px;
    --h2: 30px;
  }
}
