.home__landing {
  min-height: calc(100vh - var(--header-height));
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home__landing h1 {
  color: var(--first-color);
  margin-bottom: 20px;
}
.home__searchBox {
  padding: 15px 20px 15px 20px;
  border-radius: 5000px;
  width: 479px;
  display: flex;
  background-color: var(--second-color);
  justify-content: space-between;
  color: var(--contrast-second-color);
  margin: 20px 0px;
}
.home__landing button {
  margin-right: 15px;
}
/* -- About US -------------------------------- */

.home__about {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
}
.home__aboutImg {
  width: 533px;
  height: 362px;
}
.home__aboutContent {
  display: flex;
  padding-left: 40px;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.home__aboutContent h1 {
  margin-top: 30px;
  margin-bottom: 20px;
}
.home__aboutContent p {
  font-size: var(--body-large);
  color: var(--gray-dark);
}
.home__aboutSocialMedia {
  margin-top: 20px;
  display: flex;
}
.home__aboutSocialMedia svg {
  padding: 10px;
  width: 50px;
  cursor: pointer;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 24px;
  background: var(--first-color);
  fill: var(--contrast-first-color);
  margin-right: 27px;
}
.home__aboutSocialMedia svg:nth-last-child(1) {
  margin: 0;
}
.home__aboutMore {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  margin-right: 10px;
}

/* == Recents ==================================================== */
.recentPost {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.recentPost__container {
  display: flex;
  margin-top: 50px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

/*
 * ─── RESPONSIVE ─────────────────────────────────────────────────────────────────
 */

@media screen and (max-width: 1074px) {
  .home__aboutImg {
    width: 433px;
    height: 262px;
  }
}

@media screen and (max-width: 1000px) {
  .recentPost__container {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media screen and (max-width: 932px) {
  /* Home - Landing */
  .home__landing {
    flex-direction: column-reverse;
    text-align: center;
    justify-content: center;
    min-height: auto;
    margin-top: 50px;
  }
  .home__landingTexts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /* Home - About */
  .home__about {
    flex-direction: column;
    margin: 100px 0px;
    justify-content: center;
    min-height: auto;
  }
  .home__aboutContent {
    align-items: center;
    text-align: center;
    margin-top: 50px;
    padding-left: 0px;
  }
}

@media screen and (max-width: 500px) {
  .home__searchBox {
    width: 90%;
  }
  .home__aboutImg {
    width: 90%;
    height: 200px;
  }
  .home__image {
    width: 90%;
  }
  .home__aboutSocialMedia svg {
    margin-right: 10px;
  }
  .home__aboutSocialMedia svg:nth-last-child(1) {
    margin: 0;
  }
}
