.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: var(--yellow-light);
  color: var(--contrast-yellow-light);
  margin-top: 50px;
}
.footer__details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.footer__nav {
  display: flex;
  flex-direction: column;
}
.footer__nav a {
  font-size: 18px;
  margin-top: 5px;
}
.footer__nav h4 {
  color: var(--gray-dark);
  margin-bottom: 5px;
}
.footer__contact {
  font-size: var(--body-large);
}
.footer__contact h3 {
  color: var(--first-color);
  margin-bottom: 10px;
}
.footer__creadits {
  padding-bottom: 10px;
}
.footer__creadits a {
  font-weight: bold;
  font-size: 18px;
  text-decoration: underline;
  color: var(--redr-dark);
}
.footer__navLink {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer__navLink svg {
  margin-right: 5px;
}
@media screen and (max-width: 600px) {
  .footer__details {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .footer__contact {
    margin: 20px;
  }
  .footer__creadits {
    margin-top: 20px;
  }
}
