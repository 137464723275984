.search {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  margin-top: 50px;
}

.search__input {
  padding: 15px 20px 15px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font: inherit;
  border: none;
  outline: none;
  width: 100%;
  background: #fff;
}
.search__hitContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px 0px 0px 0px;
  border-radius: 4px;
}
.search__hit {
  border: var(--outline);
  display: flex;
  border-color: hsl(0, 0%, 80%);
  flex-direction: row;
  padding: 20px 30px;
  background: rgba(228, 226, 226, 0.1);
  border-radius: 4px;
  cursor: pointer;
  align-items: stretch;
  justify-content: space-between;
  margin: 0px 0px 20px 0px;
}
.search__hitHeadings {
  flex: 1;
}
.search__hitInfo h5 {
  background: var(--first-color);
  text-align: center;
  padding: 5px 10px;
  border-radius: 500px;
}
.search__hitHeadings h3 {
  margin-bottom: 5px;
}

.search .card {
  margin: 20px 0px;
}

@media screen and (max-width: 1008px) {
  .search__hitContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
