.admin__logout {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: var(--z-modal);
}

/*
 * ────────────────────────────────────────────────────────────────── I ──────────
 *   :::::: A D M I N   -   L O G I N : :  :   :    :     :        :          :
 * ────────────────────────────────────────────────────────────────────────────
 */

.adminLogin {
  height: calc(100vh - var(--header-height));
  display: flex;
  justify-content: center;
  align-items: stretch;
  text-align: center;
  flex-direction: column;
}
.adminLogin h1 {
  color: var(--first-color);
  opacity: 0.7;
  text-align: center;
  margin-bottom: 50px;
}
.adminLogin__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.adminLogin__action button {
  margin-top: 20px;
  width: 100%;
  background-color: var(--second-color);
  color: var(--contrast-second-color);
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 20px;
}

.admin__backTo {
  position: absolute;
  z-index: calc(100 + var(--z-modal));
  top: 20px;
  right: 20px;
  font-size: var(--body-small);
}
.tox-statusbar__branding {
  display: none !important;
}

/* * ────────────────────────────────────────────────────────────────── I ──────────
 *   :::::: A D M I N   -   E D I T  :  :   :    :     :        :          :
 * ────────────────────────────────────────────────────────────────────────────
 */
.adminedit,
.adminAdd {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 0px 0px 50px 0px;
}
.adminedit__title,
.adminAdd__title {
  width: 100%;
  border: none;
  resize: none;
  outline: none;
}
.adminedit__caption,
.adminAdd__caption {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}

.admin__hitInfo {
  flex: 0.1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.admin__edit {
  border-radius: 15px !important;
  background: orange !important;
  margin: 5px 0px;
}
.admin__del {
  border-radius: 15px !important;
}
.admin__addBtns {
  position: fixed;
  padding: 10px;
  bottom: 20px;
  right: 10px;
}
.admin__addBtns button {
  padding: 10px 20px;
  border-radius: 5px;
  background: #757ce8;
  color: white;
  margin: 0px 10px;
  font: inherit;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  outline: none;
}

/* ==== Admin Form ================================================ */
.adminForm {
  padding: 30px var(--body-padding);
}

/* Admin Form - Tags */
.admin__fromTagInputField {
  border: 1px solid #00000004;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
}
.admin__fromTagSelected {
  margin-bottom: 30px;
}
.admin__formTag {
  border: 1px solid #00000004;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 10px 20px;
  background: #fff;
  font-family: var(--body-font);
  font-weight: 700;
  margin: 0px 10px;
}
.admin__formTag:nth-child(1) {
  margin: 0px;
}
.admin__fromTagRemove {
  background: #fff;
  border: none;
  outline: none;
  font-size: var(--body-large);
  font-weight: 700;
  margin-left: 10px;
  line-height: 0%;
}

/* Admin Form - Title */
.adminForm__title {
  width: 100%;
  resize: vertical;
  border: none;
  outline: none;
  margin: 10px 0px;
  background: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  font-size: var(--h1);
  line-height: var(--h1-lh);
  font-weight: bold;
  margin-top: 20px;
}

/* Admin From - Caption */
.adminForm__caption {
  resize: vertical;
}

/* Admin Form - Submit */
.adminForm__submit {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.admin__cardActions {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  padding-bottom: 10px;
}
.admin__cardActions button {
  flex: 1;
  border-radius: 0px !important;
}
