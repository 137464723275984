@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap);
.header {
  padding: 0px var(--body-padding);
  height: var(--header-height);
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  background: var(--body-light);
  top: 0;
  left: 0;
  width: 100vw;
  z-index: var(--z-fixed);
}
.header li {
  list-style: none;
}

/* Header - Title [ GURU ] */
.header__title {
  color: var(--first-color);
  flex: 1 1;
}

/* Header - Navigation [ Home, About ] */
.header__links {
  height: 24px;
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header__links a {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.header__links svg {
  color: var(--body-dark);
  margin-right: 5px;
}

/* Header - Socila Media [ Facebook, Youtube ] */
.header__socialMedia {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
}
.header__socialMedia svg {
  color: var(--body-dark);
  margin-left: 10px;
}
.header__shadow {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}

/* Header - MenuBar */
.header__menubar {
  display: none;
  padding: var(--body-padding);
}

/* Navigation State */
.navHidden {
  display: none;
}
.navVisible {
  display: flex;
}

/* Responsive - [ Navigation, MenuBar, SocialMedia ] */
@media screen and (max-width: 932px) {
  /* MenuBar */
  .header__menubar {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    height: var(--header-height);
    justify-content: center;
    align-items: center;
    background-color: var(--body-light);
    z-index: calc(100 + var(--z-modal));
  }

  /* Social Media */
  .header__socialMedia {
    position: absolute;
    bottom: calc(var(--header-height) + 50px - 100vh);
    left: 0;
    width: 100vw;
    background-color: var(--body-light);
    justify-content: center;
    z-index: var(--z-modal);
  }
  .header__socialMedia svg {
    color: var(--body-dark);
    width: 50px;
  }

  /* Navigation */
  .header__links {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: var(--body-light);
    flex-direction: column;
    min-height: 100vh;
    z-index: var(--z-modal);
  }

  .header__links a {
    margin: 20px;
    font-size: var(--body-large);
    width: 160px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: var(--yellow-light);
  color: var(--contrast-yellow-light);
  margin-top: 50px;
}
.footer__details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.footer__nav {
  display: flex;
  flex-direction: column;
}
.footer__nav a {
  font-size: 18px;
  margin-top: 5px;
}
.footer__nav h4 {
  color: var(--gray-dark);
  margin-bottom: 5px;
}
.footer__contact {
  font-size: var(--body-large);
}
.footer__contact h3 {
  color: var(--first-color);
  margin-bottom: 10px;
}
.footer__creadits {
  padding-bottom: 10px;
}
.footer__creadits a {
  font-weight: bold;
  font-size: 18px;
  text-decoration: underline;
  color: var(--redr-dark);
}
.footer__navLink {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer__navLink svg {
  margin-right: 5px;
}
@media screen and (max-width: 600px) {
  .footer__details {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .footer__contact {
    margin: 20px;
  }
  .footer__creadits {
    margin-top: 20px;
  }
}

.button {
  padding: 10px 20px;
  font-family: var(--body-font);
  font-size: var(--body-regular);
  border: none;
  outline: none;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
}
.bg-first {
  background-color: var(--first-color);
  color: var(--contrast-first-color);
}
.bg-second {
  background-color: var(--second-color) !important;
  color: var(--contrast-second-color) !important;
}
.border-rounded {
  border-radius: 50000px !important;
}
.border-sm {
  border-radius: 5px !important;
}
.shadow-sm {
  box-shadow: 0px 2px 10px 0px #00000040;
}
.shadow-0 {
  box-shadow: none;
}
.bg-red {
  background-color: var(--red-color) !important;
  color: #fff;
}

.card {
  width: 450px;
  border-radius: 12px;
  box-shadow: 0px 1px 14px 0px #0000001a;
  overflow: hidden;
  height: auto;
  padding-bottom: 20px;
}
.card__image {
  width: 450px;
  height: 300px;
}
.card__author {
  display: flex;
  padding: 15px 0px 15px 20px;
  align-items: center;
}
.card__author p {
  margin-left: 10px;
}
.card__content {
  padding: 0px 20px 0px 20px;
}
.card__content p {
  color: var(--gray-dark);
}

@media screen and (max-width: 480px) {
  .card {
    width: 90%;
    margin-bottom: 20px;
  }
  .card__image {
    width: 100%;
  }
}

.textfield {
  padding: 15px 20px 15px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font: inherit;
  border: none;
  outline: none;
  width: 100%;
  background: #fff;
}

.commingsoon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - var(--header-height));
  text-align: center;
}
@media screen and (max-width: 550px) {
  .commingsoon img {
    width: 90%;
  }
}

.notfound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - var(--header-height));
  text-align: center;
}

@media screen and (max-width: 550px) {
  .notfound img {
    width: 90%;
  }
}

.home__landing {
  min-height: calc(100vh - var(--header-height));
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home__landing h1 {
  color: var(--first-color);
  margin-bottom: 20px;
}
.home__searchBox {
  padding: 15px 20px 15px 20px;
  border-radius: 5000px;
  width: 479px;
  display: flex;
  background-color: var(--second-color);
  justify-content: space-between;
  color: var(--contrast-second-color);
  margin: 20px 0px;
}
.home__landing button {
  margin-right: 15px;
}
/* -- About US -------------------------------- */

.home__about {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
}
.home__aboutImg {
  width: 533px;
  height: 362px;
}
.home__aboutContent {
  display: flex;
  padding-left: 40px;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.home__aboutContent h1 {
  margin-top: 30px;
  margin-bottom: 20px;
}
.home__aboutContent p {
  font-size: var(--body-large);
  color: var(--gray-dark);
}
.home__aboutSocialMedia {
  margin-top: 20px;
  display: flex;
}
.home__aboutSocialMedia svg {
  padding: 10px;
  width: 50px;
  cursor: pointer;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 24px;
  background: var(--first-color);
  fill: var(--contrast-first-color);
  margin-right: 27px;
}
.home__aboutSocialMedia svg:nth-last-child(1) {
  margin: 0;
}
.home__aboutMore {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  margin-right: 10px;
}

/* == Recents ==================================================== */
.recentPost {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.recentPost__container {
  display: flex;
  margin-top: 50px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

/*
 * ─── RESPONSIVE ─────────────────────────────────────────────────────────────────
 */

@media screen and (max-width: 1074px) {
  .home__aboutImg {
    width: 433px;
    height: 262px;
  }
}

@media screen and (max-width: 1000px) {
  .recentPost__container {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media screen and (max-width: 932px) {
  /* Home - Landing */
  .home__landing {
    flex-direction: column-reverse;
    text-align: center;
    justify-content: center;
    min-height: auto;
    margin-top: 50px;
  }
  .home__landingTexts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /* Home - About */
  .home__about {
    flex-direction: column;
    margin: 100px 0px;
    justify-content: center;
    min-height: auto;
  }
  .home__aboutContent {
    align-items: center;
    text-align: center;
    margin-top: 50px;
    padding-left: 0px;
  }
}

@media screen and (max-width: 500px) {
  .home__searchBox {
    width: 90%;
  }
  .home__aboutImg {
    width: 90%;
    height: 200px;
  }
  .home__image {
    width: 90%;
  }
  .home__aboutSocialMedia svg {
    margin-right: 10px;
  }
  .home__aboutSocialMedia svg:nth-last-child(1) {
    margin: 0;
  }
}

.post {
  padding: 107px var(--body-padding);
}
.post__action {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
.post__author {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.post__author h5 {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: 0.5px;
  margin-left: 10px;
}
.post__title {
  margin: 15px 0;
}
.post__time {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.5);
}
.post__content {
  margin-top: 30px;
  font-family: "Rubik";
  font-size: var(--body-large);
  line-height: 135%;
  word-spacing: 5px;
}
.post__content h1,
.post__content h2 {
  margin-bottom: 20px;
}

.post__caption {
  color: rgba(0, 0, 0, 0.5);
  font-size: var(--body-large);
  line-height: 135%;
  letter-spacing: 0.2px;
}
.post__category {
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5000px;
  background-color: var(--first-color);
  width: auto;
  position: absolute;
}
.post__share {
  padding: 20px 20px 20px 0px;
}
.post__share svg {
  margin: 0px 10px;
  cursor: pointer;
}

.post__content li {
  margin-left: 25px;
}

@media screen and (max-width: 548px) {
  .post__content li {
    margin-left: 5px;
  }
  .post {
    padding: 107px 0px;
  }
  .post__content {
    font-size: var(--body-regular);
  }
}
@media screen and (max-width: 396px) {
  .post__content {
    font-size: 15px;
  }
}

.search {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  margin-top: 50px;
}

.search__input {
  padding: 15px 20px 15px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font: inherit;
  border: none;
  outline: none;
  width: 100%;
  background: #fff;
}
.search__hitContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px 0px 0px 0px;
  border-radius: 4px;
}
.search__hit {
  border: var(--outline);
  display: flex;
  border-color: hsl(0, 0%, 80%);
  flex-direction: row;
  padding: 20px 30px;
  background: rgba(228, 226, 226, 0.1);
  border-radius: 4px;
  cursor: pointer;
  align-items: stretch;
  justify-content: space-between;
  margin: 0px 0px 20px 0px;
}
.search__hitHeadings {
  flex: 1 1;
}
.search__hitInfo h5 {
  background: var(--first-color);
  text-align: center;
  padding: 5px 10px;
  border-radius: 500px;
}
.search__hitHeadings h3 {
  margin-bottom: 5px;
}

.search .card {
  margin: 20px 0px;
}

@media screen and (max-width: 1008px) {
  .search__hitContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.admin__logout {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: var(--z-modal);
}

/*
 * ────────────────────────────────────────────────────────────────── I ──────────
 *   :::::: A D M I N   -   L O G I N : :  :   :    :     :        :          :
 * ────────────────────────────────────────────────────────────────────────────
 */

.adminLogin {
  height: calc(100vh - var(--header-height));
  display: flex;
  justify-content: center;
  align-items: stretch;
  text-align: center;
  flex-direction: column;
}
.adminLogin h1 {
  color: var(--first-color);
  opacity: 0.7;
  text-align: center;
  margin-bottom: 50px;
}
.adminLogin__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.adminLogin__action button {
  margin-top: 20px;
  width: 100%;
  background-color: var(--second-color);
  color: var(--contrast-second-color);
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 20px;
}

.admin__backTo {
  position: absolute;
  z-index: calc(100 + var(--z-modal));
  top: 20px;
  right: 20px;
  font-size: var(--body-small);
}
.tox-statusbar__branding {
  display: none !important;
}

/* * ────────────────────────────────────────────────────────────────── I ──────────
 *   :::::: A D M I N   -   E D I T  :  :   :    :     :        :          :
 * ────────────────────────────────────────────────────────────────────────────
 */
.adminedit,
.adminAdd {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 0px 0px 50px 0px;
}
.adminedit__title,
.adminAdd__title {
  width: 100%;
  border: none;
  resize: none;
  outline: none;
}
.adminedit__caption,
.adminAdd__caption {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}

.admin__hitInfo {
  flex: 0.1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.admin__edit {
  border-radius: 15px !important;
  background: orange !important;
  margin: 5px 0px;
}
.admin__del {
  border-radius: 15px !important;
}
.admin__addBtns {
  position: fixed;
  padding: 10px;
  bottom: 20px;
  right: 10px;
}
.admin__addBtns button {
  padding: 10px 20px;
  border-radius: 5px;
  background: #757ce8;
  color: white;
  margin: 0px 10px;
  font: inherit;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  outline: none;
}

/* ==== Admin Form ================================================ */
.adminForm {
  padding: 30px var(--body-padding);
}

/* Admin Form - Tags */
.admin__fromTagInputField {
  border: 1px solid #00000004;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
}
.admin__fromTagSelected {
  margin-bottom: 30px;
}
.admin__formTag {
  border: 1px solid #00000004;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 10px 20px;
  background: #fff;
  font-family: var(--body-font);
  font-weight: 700;
  margin: 0px 10px;
}
.admin__formTag:nth-child(1) {
  margin: 0px;
}
.admin__fromTagRemove {
  background: #fff;
  border: none;
  outline: none;
  font-size: var(--body-large);
  font-weight: 700;
  margin-left: 10px;
  line-height: 0%;
}

/* Admin Form - Title */
.adminForm__title {
  width: 100%;
  resize: vertical;
  border: none;
  outline: none;
  margin: 10px 0px;
  background: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  font-size: var(--h1);
  line-height: var(--h1-lh);
  font-weight: bold;
  margin-top: 20px;
}

/* Admin From - Caption */
.adminForm__caption {
  resize: vertical;
}

/* Admin Form - Submit */
.adminForm__submit {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.admin__cardActions {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  padding-bottom: 10px;
}
.admin__cardActions button {
  flex: 1 1;
  border-radius: 0px !important;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: var(--body-dark);
}

body {
  background: var(--body-light);
  font-size: var(--body-regular);
  font-weight: var(--font-regular);
  font-family: var(--body-font);
  padding: var(--header-height) var(--body-padding) 0px var(--body-padding);
  color: var(--body-dark);
  min-height: 100vh;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
}

h1 {
  font-size: var(--h1);
  line-height: var(--h1-lh);
}
h2 {
  font-size: var(--h2);
  line-height: var(--h2-lh);
}
h3 {
  font-size: var(--h2);
  line-height: var(--h3-lh);
}
h4 {
  font-size: var(--h4);
  line-height: var(--h4-lh);
  letter-spacing: 1px;
}

:root {
  --header-height: 60px;
  --body-padding: 111px;

  /* ------------------------ Color ----------------------- */

  --first-color: #8b0bff;
  --contrast-first-color: #f7faff;

  --second-color: #1fe062;
  --contrast-second-color: #f7faff;

  --red-color: #ff4444;

  --body-light: #f7faff;
  --body-dark: #091127;

  --gray-light: #eaeaea;
  --gray-dark: #848282;

  /* ----------------- Font And Typography ---------------- */
  --body-font: "Rubik", sans-serif;
  --body-large: 20px;
  --body-regular: 16px;
  --body-small: 12px;

  --font-regular: 400;
  --font-bold: 700;

  --h1: 40px;
  --h1-lh: 50px;

  --h2: 32px;
  --h2-lh: 45px;

  --h3: 28px;
  --h3-lh: 36px;
  --h4: 20px;
  --h4-lh: 24px;

  /* ----------------------- Z-Index ---------------------- */
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 10000;
}

@media screen and (max-width: 1074px) {
  :root {
    --body-padding: 50px;
  }
}

@media screen and (max-width: 932px) {
  :root {
    --body-padding: 30px;
  }
}

@media screen and (max-width: 550px) {
  :root {
    --body-padding: 10px;
    --body-large: 18px;
  }
}
@media screen and (max-width: 300px) {
  :root {
    --body-padding: 5px;
  }
}

@media screen and (max-width: 450px) {
  :root {
    --h1: 36px;
    --h2: 30px;
  }
}



