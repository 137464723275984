.post {
  padding: 107px var(--body-padding);
}
.post__action {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
.post__author {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.post__author h5 {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: 0.5px;
  margin-left: 10px;
}
.post__title {
  margin: 15px 0;
}
.post__time {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.5);
}
.post__content {
  margin-top: 30px;
  font-family: "Rubik";
  font-size: var(--body-large);
  line-height: 135%;
  word-spacing: 5px;
}
.post__content h1,
.post__content h2 {
  margin-bottom: 20px;
}

.post__caption {
  color: rgba(0, 0, 0, 0.5);
  font-size: var(--body-large);
  line-height: 135%;
  letter-spacing: 0.2px;
}
.post__category {
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5000px;
  background-color: var(--first-color);
  width: auto;
  position: absolute;
}
.post__share {
  padding: 20px 20px 20px 0px;
}
.post__share svg {
  margin: 0px 10px;
  cursor: pointer;
}

.post__content li {
  margin-left: 25px;
}

@media screen and (max-width: 548px) {
  .post__content li {
    margin-left: 5px;
  }
  .post {
    padding: 107px 0px;
  }
  .post__content {
    font-size: var(--body-regular);
  }
}
@media screen and (max-width: 396px) {
  .post__content {
    font-size: 15px;
  }
}
